import React, {useState} from 'react';
import axiosInstance from '../api/axiosInstance'; // Adjust the import path as necessary
import {storeEmail, storeTokens} from '../auth/auth';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import logo from "../logo.svg"

interface LoginComponentProps {
  onLogin: (username: string) => void,
  setLoggedInUsername: (value: string) => void;
}

const LoginComponent: React.FC<LoginComponentProps> = ({onLogin, setLoggedInUsername}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<{ email: string; password: string }>({email: '', password: ''});
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Email validation function
  const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const emailError = validateEmail(email) ? '' : 'Invalid email address';
    setErrors({email: emailError, password: ''});

    if (emailError) return;

    setIsLoading(true);
    setMessage('');

    try {
      const response = await axiosInstance.post(
        '/api/auth/login',
        {email, password},
        {skipAuth: true}
      );

      storeTokens(response.data.access_token, response.data.refresh_token);
      storeEmail(email);
      setMessage('Login successful!');

      // Trigger the onLogin function to update App’s state
      onLogin(email);
      setLoggedInUsername(email);
    } catch (error: any) {
      console.error(error);
      setMessage('Authentication failed. Please check your credentials.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div>
        <Container className="d-flex flex-column align-items-center" style={{maxWidth: '400px', marginTop: '2rem'}}>
          <Box mb={2}>
            <img src={logo} alt="Company Logo" style={{maxWidth: '100%', height: 'auto'}}/>
          </Box>
          <Typography variant="h5" component="h1" gutterBottom>
            Login
          </Typography>
          {isLoading ? (
            <CircularProgress/>
          ) : (
            <form onSubmit={handleSubmit} style={{width: '100%'}}>
              <TextField
                autoComplete="username"
                autoFocus={true}
                label="Email"
                type="email"
                fullWidth
                margin="normal"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                label="Password"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                margin="normal"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {message && <p className="error-message show">{message}</p>}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{marginTop: '1rem'}}
              >
                Login
              </Button>
            </form>
          )}
        </Container>
      </div>
    </div>
  );
};

export default LoginComponent;
