import './App.css';
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsClusters from './NewsClusters';
import {NewsCluster} from "./types";
import {userIsLoggedIn} from "./auth/auth";
import axiosInstance from "./api/axiosInstance";
import LoginComponent from "./components/LoginComponent"
import {sleep} from "./utils/utils";
import {CircularProgress} from "@mui/material";

const App = () => {
  const [newsClusters, setNewsClusters] = useState<NewsCluster[]>([]);
  const [loggedInUsername, setLoggedInUsername] = useState<string>(() => {
    return localStorage.getItem('loggedInUsername') || ""; // Default to empty string if not found
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(userIsLoggedIn()); // Track login status in state

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      axiosInstance.get("/api/catholic_news_summary").then((res) => {
        setNewsClusters(res.data.data);
        setIsLoading(false);
      });
    }
  }, [isLoggedIn]); // Trigger this effect when login status changes

  useEffect(() => {
    localStorage.setItem('loggedInUsername', loggedInUsername);
  }, [loggedInUsername]);

  // Function to render the protected content after login
  const renderProtectedContent = () => (
    <div>
      {isLoading ? (
        <CircularProgress/>
      ) : (
        <NewsClusters
          clusters={newsClusters}
          username={loggedInUsername}
          full_name=""
          setIsLoggedIn={setIsLoggedIn}
        />
      )}
    </div>
  );


  // Update login state when login is successful (e.g., in LoginComponent)
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <div className="App">
      {isLoggedIn ? renderProtectedContent() : <LoginComponent onLogin={handleLogin}
                                                               setLoggedInUsername={setLoggedInUsername}/>}
    </div>
  );
};

export default App;

