// src/auth/auth.ts
export const storeTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);
};

export const storeEmail = (email: string) => {
  localStorage.setItem('username', email);
}

export const getStoredToken = (): string => localStorage.getItem('access_token') || '';

export const getStoredRefreshToken = (): string => localStorage.getItem('refresh_token') || '';

export const userIsLoggedIn = (): boolean => getStoredToken() !== '';

export const clearTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
};
